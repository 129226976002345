import QueryString from 'qs'

export async function $customFetch<T>(
  url: string | (() => string),
  query: Record<string, unknown> = {},
  thumbs: Record<string, unknown> | null = null,
  options: { [attr: string]: string | object } = {},
  recaptchaActionName?: string,
  abortPropsController?: AbortController
) {
  const { auth } = useAuth()
  const { basket } = useBasketService()
  const { public: config } = useRuntimeConfig()
  const { config: userConfig } = useUserConfig()
  const { getRecaptchaHeaders } = useRecaptcha()
  const objectQuery: Record<string, unknown> = { ...query }
  if (thumbs) {
    objectQuery.thumb = thumbs
  }
  const queryString = QueryString.stringify(objectQuery)
  const headers: { [header: string]: string } = {
    Accept: 'application/json',
    'X-Currency': userConfig.value.currency,
    'X-Lang': config.locales[userConfig.value.lang as 'cs' | 'en' | 'ru'],
    Authorization: `Bearer ${auth.token}`,
  }
  if (basket.value.guid) {
    headers['X-Basket-Id'] = basket.value.guid
  }
  const fetchUrl = `${config.apiBaseUrl}${url}${queryString ? `?${queryString}` : ''}`
  if (recaptchaActionName) {
    const { token, version } = await getRecaptchaHeaders(recaptchaActionName)
    headers[version] = token
  }
  return $fetch<T>(fetchUrl, {
    ...options,
    headers: {
      ...(typeof options.headers !== 'string' ? options.headers : {}),
      ...headers,
    },
    signal: abortPropsController?.signal,
  })
}
