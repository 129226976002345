import type { Auth, SessionData } from '~/types/auth'
import type { RouteLocation, RouteLocationRaw } from 'vue-router'
// Auth plugin
// Login a Logout metody
// Držení Laravel Sanctum tokenu v cookie a uživatele v localStorage (pouze client)

interface AuthModule {
  login: (email: string, password: string, to?: RouteLocation | RouteLocationRaw) => Promise<void>
  logout: (logoutOnBackend?: boolean) => Promise<void>
  setNewPassword: (token: string, emial: string, password: string) => Promise<void>
  requestNewPassword: (email: string) => Promise<void>
}

declare module '#app' {
  interface NuxtApp {
    $auth: AuthModule
  }
}

export default defineNuxtPlugin(() => {
  const tokenCookieKey = 'auth-token'
  const backLinkKey = 'back-link'
  const userKey = 'user'
  const { public: config } = useRuntimeConfig()

  const localePath = useLocalePath()
  const { loadBasket } = useBasketService()

  const { apiBaseUrl } = config
  const { browser, os } = useGetBrowser()
  // const {
  //    $sentry,
  // } = nuxtApp

  const auth = useState<Auth>('auth', () => {
    return {
      user: null,
      token: null,
    }
  })

  addRouteMiddleware('auth', async (to) => {
    loadBasket()
    if (to.meta.middleware !== 'guest' && to.meta.middleware !== 'link') {
      if (auth.value.token === null) {
        getToken()
      }
      if (auth.value.user === null && import.meta.client) {
        const user = localStorage.getItem(userKey)
        auth.value.user = user ? JSON.parse(user) : null
      }

      if (auth.value.token === null) {
        if (import.meta.client) {
          localStorage.setItem(backLinkKey, window.location.pathname + window.location.search)
        }
        return localePath({ name: 'login' })
      }
    }
    if (to.meta.logoutPage) {
      logout()
    }
  })

  addRouteMiddleware(
    'guest',
    async (to) => {
      if (auth.value.token === null) {
        getToken()
      }
      loadBasket()

      if (to.meta.loginPage && auth.value.token !== null) {
        return localePath({ name: 'home' })
      }
    },
    { global: true }
  )

  const getToken = () => {
    auth.value.token = useCookie(tokenCookieKey, { sameSite: true })?.value || null
  }

  const setToken = (token: string) => {
    useCookie(tokenCookieKey, { sameSite: true }).value = token
  }

  const clearToken = () => {
    useCookie(tokenCookieKey, { sameSite: true }).value = null
  }

  const setNewPassword: AuthModule['setNewPassword'] = async (token, email, password) => {
    try {
      await $fetch<{ data: SessionData }>(`${apiBaseUrl}/account/recovery`, {
        method: 'POST',
        headers: { Accept: 'application/json' },
        body: {
          token,
          email,
          password,
        },
      })
    } catch (e) {
      throw e
    }
  }

  const requestNewPassword: AuthModule['requestNewPassword'] = async (email) => {
    try {
      await $fetch<{ data: SessionData }>(`${apiBaseUrl}/account/reset`, {
        method: 'POST',
        headers: { Accept: 'application/json' },
        body: {
          email,
        },
      })
    } catch (e) {
      throw e
    }
  }

  const login: AuthModule['login'] = async (
    email,
    password,
    to?: RouteLocation | RouteLocationRaw
  ) => {
    try {
      const { data } = await $fetch<{ data: SessionData }>(
        `${apiBaseUrl}/sessions`,
        {
          method: 'POST',
          headers: { Accept: 'application/json' },
          body: {
            username: email,
            password,
            tokenName: 'eshop-client',
            device: {
              os,
              browser,
              type: 'eshop-client',
            },
          },
        },
        null,
        { method: 'POST' }
      )
      setToken(data.token)
      if (import.meta.client) {
        if (data.identity) {
          localStorage.setItem(userKey, JSON.stringify(data.identity))
        }
      }
      // if (
      //   $sentry &&
      //   typeof $sentry === 'object' &&
      //   'setUser' in $sentry &&
      //   typeof $sentry.setUser === 'function'
      // ) {
      //   $sentry.setUser({
      //     username: data.identity.username,
      //     email: data.identity.email,
      //     id: data.identity.id,
      //   })
      // }
      const backLink = to ?? localStorage.getItem(backLinkKey) ?? { name: 'home' }
      localStorage.removeItem(backLinkKey)
      navigateTo(localePath(backLink))
    } catch (e) {
      throw e
    }
  }

  const logout: AuthModule['logout'] = async (logoutOnBackend = true) => {
    if (logoutOnBackend) {
      await $fetch<{ data: SessionData }>(`${apiBaseUrl}/sessions`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${auth.value.token}`,
        },
      })
    }
    clearToken()
    if (process.client) {
      localStorage.removeItem(userKey)
    }
    auth.value = {
      token: null,
      user: null,
    }
    // if (
    //   $sentry &&
    //   typeof $sentry === 'object' &&
    //   'setUser' in $sentry &&
    //   typeof $sentry.setUser === 'function'
    // ) {
    //   $sentry.setUser(null)
    // }
    navigateTo(localePath({ name: 'login' }))
  }

  return {
    provide: {
      auth: {
        login,
        logout,
        requestNewPassword,
        setNewPassword,
      },
    },
  }
})
